<template>
  <AppHeader />

  <main class="content">
    <router-view></router-view>
  </main>

  <AppFooter />
</template>

<script>
import AppFooter from "@/components/common/AppFooter.vue";
import AppHeader from "@/components/common/AppHeader.vue";

export default {
  name: "DefaultLayout",
  data() {
    return {};
  },
  methods: {},
  components: {
    AppFooter,
    AppHeader,
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 160px; */
  gap: clamp(4rem, 1.4455rem + 9.505vw, 10rem);
}
</style>
