import { createRouter, createWebHistory } from "vue-router";
import { availableLanguages } from "@/utils/constants";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { PATHS } from "@/data/nav";

const routes = [
  {
    path: "/:lang",
    component: DefaultLayout,
    children: [
      {
        path: "/:lang",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: PATHS.CONFERENCE,
        name: "conference",
        component: () => import("@/views/ConferenceView.vue"),
      },
      {
        path: PATHS.SPEAKERS,
        name: "speakers",
        component: () => import("@/views/SpeakersView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.ABOUT,
        name: "about",
        component: () => import("@/views/AboutView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.RESEARCH,
        name: "research",
        component: () => import("@/views/ResearchView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.ARTISTIC_RESIDENCY,
        name: "artFellowship",
        component: () => import("@/views/ArtFellowshipView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.GET_INVOLVED,
        name: "getInvolved",
        component: () => import("@/views/GetInvolvedView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.PRIVACY_POLICY,
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.IMPRINT,
        name: "imprint",
        component: () => import("@/views/ImprintView.vue"),
        meta: { translateRouteParams: true },
      },
    ],
  },
  {
    path: PATHS.ROOT,
    redirect: `/${availableLanguages[0]}`, // Redirect to default language
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "",
        name: "notFound",
        component: () => import("@/views/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// Navigation guard to handle language routing
// router.beforeEach((to, from, next) => {
//   const lang = to.params.lang;

//   if (!lang) {
//     next("/en");
//   } else if (!availableLanguages.includes(lang)) {
//     next("/en");
//   } else {
//     next();
//   }
// });

export default router;
