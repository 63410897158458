import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import { availableLanguages } from "./utils/constants";

// Import all language files
const messages = {};
availableLanguages.forEach((lang) => {
  messages[lang] = require(`./locales/${lang}.json`);
});

// Create i18n instance
const i18n = createI18n({
  legacy: false,
  locale: "en", // default locale
  fallbackLocale: "en",
  messages,
});

const app = createApp(App);

app.use(router);
app.use(i18n);

router.beforeEach((to, from, next) => {
  // Update locale based on route
  const lang = to.params.lang;

  if (availableLanguages.includes(lang)) {
    i18n.global.locale.value = lang;
  }
  next();
});

app.mount("#app");
