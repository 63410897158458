export const PATHS = {
  ROOT: "/",
  CONFERENCE: "conference",
  ABOUT: "about",
  SPEAKERS: "speakers",
  ARTISTIC_RESIDENCY: "art-fellowship",
  PRIVACY_POLICY: "privacy-policy",
  IMPRINT: "imprint",
  RESEARCH: "research",
  GET_INVOLVED: "get-involved",

  // forms
  NEWSLETTER_SIGNUP:
    "https://actionnetwork.org/forms/sing-up-for-truth-to-justice-updates",
  CONFERENCE_REGISTRATION:
    "https://actionnetwork.org/forms/truth-to-justice-conference-registration/",
};

export function getNavigation(currentLang) {
  return [
    {
      title: "nav.home",
      route: `/${currentLang}`,
      show: true,
      header: true,
      footer: false,
    },
    {
      title: "nav.conference",
      route: `/${currentLang}/${PATHS.CONFERENCE}`,
      show: true,
      header: true,
      footer: false,
    },
    {
      title: "nav.about_us",
      route: `/${currentLang}/${PATHS.ABOUT}`,
      show: false,
      header: true,
      footer: false,
    },
    {
      title: "nav.speakers",
      route: `/${currentLang}/${PATHS.SPEAKERS}`,
      show: false,
      header: true,
      footer: false,
    },
    {
      title: "nav.artistic_residency",
      route: `/${currentLang}/${PATHS.ARTISTIC_RESIDENCY}`,
      show: false,
      header: true,
      footer: false,
    },
  ];
}
